<template>
    <div class="page">
        <div class="">
            <UberEventList v-if="!selectedEvent && !selectedUberEvent" @refresh="fetchData" />
        </div>
        <UberEventDetails v-if="selectedUberEvent" :key="`ue${selectedUberEvent.id}`" @refresh="fetchData" />
        <EventDetails v-if="selectedEvent" :key="`e${selectedEvent.id}`" @refresh="fetchData" />
    </div>
</template>

<script>
export default {
    watch: {
        'selectedUberEvent.id'() {
            window.scroll(0, 0);
            this.selectedEvent = null;
            this.selectedTicketGroup = null;
            if(!this.selectedUberEvent) {
                this.$router.push({
                    query:{ id : null }
                })
                return
            }
            this.$router.push({
                query:{ id : this.selectedUberEvent.id }
            })
        },
        'selectedEvent.id'() {
            window.scroll(0, 0);
            this.selectedTicketGroup = null;
        },
    },

    methods: {
        async fetchData() {
            await this.fetchAll('settings');
            await this.fetchMany('venues', 'promoters', 'genres', 'globalDiscounts', 'sales');
            await this.fetchMany('uberEvents');

            // Refresh selected uber-event, event and ticket group (could also just clear them)

            this.selectedUberEvent = this.selectedUberEvent && this.findIn(this.uberEvents, this.selectedUberEvent);
            this.selectedEvent = this.selectedEvent && this.findIn(this.selectedUberEvent.events, this.selectedEvent);

            // Note: May select wrong group; fixing this is optional in provisional UI
            this.selectedTicketGroup = this.selectedTicketGroup && this.findIn((this.selectedEvent || this.selectedUberEvent).ticketGroups, this.selectedTicketGroup);
        },
    },

    @cancellableFetch
    async created() {
        await this.fetchData();
        if(this.$route.query && this.$route.query.id){
            try{
                console.log(this.$route.query.id);
                this.selectedUberEvent = await this.uberEvents.find(el=> el.id === +this.$route.query.id);
                console.log(this.selectedUberEvent);
            }catch (e) {
                this.selectedUberEvent = null;
                console.log(e)
            }
        }else{
            this.selectedUberEvent = null;
        }
    },

    meta: {
        title: 'Události',
    },
};
</script>

<style lang="scss" >
.bg-half-white {
    background-color: rgba(255, 255, 255, .5);
}

.clickable tr {
    cursor: pointer;
}
</style>
